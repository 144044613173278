import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const MaterialPage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Materiál" />
      <h1>Materiál</h1>
      <div className="relative z-10">
        <p className="font-bold mb-4 text-justify">
          Materiál na výrobu textilních etiket
        </p>
        <p className="mb-10 text-justify">
          Všechny materiály, které používáme při výrobě textilních etiket
          nabízíme i k prodeji. Pro tisk termotransferem, tisk z folie i pro
          sublimaci. Primárně nejsme obchodníci s materiálem, náš sklad obsahuje
          pouze to, co sami spotřebováváme, to můžeme nabídnout k prodeji. V
          případě zájmu můžeme samozřejmě zajistit i materiály v adjustaci,
          kterou sami nepoužíváme. Podrobnější informace Vám rádi sdělíme na
          konkrétní dotaz.
        </p>
      </div>
      <div className="relative z-0 -mt-16">
        <StaticImage src="../images/material/lepenky.jpg" alt="Lepenky" />
        <div className="bg-gradient-to-b from-white via-transparent  h-100 inset-0 absolute" />
      </div>
    </Layout>
  );
};

export default MaterialPage;
